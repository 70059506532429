import React from "react";
import Markdown from "react-markdown"
import { getButtonAppearance } from "/utils/button"
import ButtonLink from "../elements/button-link"
import NextImage from "../elements/image"
// import SimpleSlider from "../elements/SimpleSlider"


import { Controller, Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/controller';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import { useRouter } from "next/router";
import { PageTransitionContext } from "../page-transition";
import Arrow from "../icons/Arrow";

const Hero = ({ data, pageContext }) => {
  // console.log("HERO", data)
  const router = useRouter();
  const { addToTimeline, isLoaded, playAnimation } = React.useContext(PageTransitionContext);
  // store controlled swiper instance
  const [firstSwiper, setFirstSwiper] = React.useState(null);
  const [secondSwiper, setSecondSwiper] = React.useState(null);
  const [currentSlide, setCurrentSlide] = React.useState(1);

  const swiper1Ref = React.useRef();
  const swiper2Ref = React.useRef();
  const leftRef = React.useRef();
  const rightRef = React.useRef();
  const timeline = React.useRef();


  function slideRight(){
    swiper2Ref.current.slideNext();
  }

  function slideLeft(){
      swiper2Ref.current.slidePrev();
  }

  function onSlideChange(){
    if(swiper2Ref.current){
      // console.log(swiper2Ref.current.realIndex)
      setCurrentSlide((swiper2Ref.current.realIndex % data.sliderNewItems.length) + 1);
    }
  }

  const onComplete = () => {


        // FIX FOR FIRST LOAD, IDK WHY THE PLAIN REFRESH DOESN'T WORK ____ TODO: LOOK INTO THIS
        if (typeof(Event) === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            // for IE and other old browsers
            // causes deprecation warning on modern browsers
            var evt = window.document.createEvent('UIEvents'); 
            evt.initUIEvent('resize', true, false, window, 0); 
            window.dispatchEvent(evt);
        }
        ScrollTrigger.refresh();
        ScrollTrigger.update();
        isLoaded = true;
        // console.log("Refreshing triggers")
  }
    
  React.useEffect(() => {
    swiper2Ref.current.controller.control = swiper1Ref.current;
    swiper1Ref.current.controller.control = swiper2Ref.current;
    // swiper2Ref.current.controller.inverse = true;
    // swiper2Ref.current.controller.control = swiper1Ref.current;
    
    gsap.registerPlugin(ScrollTrigger);
    // console.log("testing", playAnimation);
    if(timeline.current){
      timeline.current.kill();
    }
    
    timeline.current = gsap.timeline()
    
    ScrollTrigger.matchMedia({

      // large
      "(min-width: 769px)": function() {
          // setup animations and ScrollTriggers for screens 960px wide or greater...
          // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.

          timeline.current.fromTo(leftRef.current ,{
              y:"100svh"
          },{
                  y: 0,
                  duration: 1.3,
                  delay: isLoaded ? 0.0 : 1.9,
                  ease:"Power3.easeOut"
          })

          timeline.current.fromTo(rightRef.current ,{
              y:"-100svh"
          },{
                  y: 0,
                  duration: 1.3,
                  ease:"Power3.easeOut"
          }, "<")

      },
      // small
      "(max-width: 768px)": function() {
          // The ScrollTriggers created inside these functions are segregated and get
          // reverted/killed when the media query doesn't match anymore. 

          timeline.current.fromTo(leftRef.current ,{
              x:"100vw"
          },{
                  x: 0,
                  duration: 1.3,
                  delay: isLoaded ? 0.1 : 1.9,
                  ease:"Power3.easeOut"
          })

          timeline.current.fromTo(rightRef.current ,{
              x:"-100vw"
          },{
                  x: 0,
                  duration: 1.3,
                  ease:"Power3.easeOut",
                  onComplete: onComplete,
          }, "<")
      },
    });


    return ()=>{
      timeline.current.kill();
    }
  }, []);
  
  return (
      <main className="w-full hero" style={{overflow:"hidden", height:"100svh"}}>
        {/* <React.Suspense fallback={null}> */}
          <div className="grid w-full h-full md:grid-cols-2">
                {/* Main Swiper -> pass controlled swiper instance */}
                <div ref={leftRef} className="w-full hero-left-side homepage-hero-left-side">
                  <Swiper
                  direction={"vertical"}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  modules={[Controller]}
                  className="mySwiper"
                  onSwiper={(swiper) => {
                    swiper1Ref.current = swiper;
                  }}
                  noSwiping={true}
                  // onSwiper={setFirstSwiper}
                  allowTouchMove={false}
                  loop={true}
                  navigation={false}
                  slidesPerView={1}
                  spaceBetween={0}
                  controller={{ control: swiper2Ref.current, inverse:true, navigation: false }}
                  // controller={{ control: secondSwiper, inverse:true }}
                  initialSlide={data.sliderNewItems.length - 1 }
                  speed={750}
                  >
                    {data.sliderNewItems.reverse().map((slide, index)=>{
                      // console.log(slide)
                      return (
                        <SwiperSlide className="slide" key={index}>
                          <div className="w-full h-full" style={{position:"relative"}}>
                            <div className="w-full h-full" style={{position:"absolute", top:"0", left:"0"}}>
                              <NextImage media={slide.slideLeftImage} style={{objectFit:"cover", objectPosition:"right", width:"100%", height:"100%"}}/>
                            </div>
                            <div className="flex flex-col justify-between w-full h-full px-8 py-5 pt-20 text-white md:pt-10 md:py-10" style={{position:"absolute", top:"0", left:"0", backgroundColor:"#00000022"}}>
                              <div></div>
                              <div>
                                <h1 className="hero-title-left">{slide.slideLeftTitle}</h1>
                                {slide.slideLeftButton ? (
                                  <ButtonLink
                                    button={slide.slideLeftButton}
                                    appearance={getButtonAppearance(slide.slideLeftButton.type, "light")}
                                    compact
                                  />
                                ) : ``}
                              </div>
                              <div>
                                <p className="hero-text-left">{slide.slideLeftDescription}</p>
                              </div>

                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
                <div ref={rightRef} className="relative w-full hero-right-side homepage-hero-left-side">
                  <Swiper
                  direction={"vertical"}
                  // pagination={{
                  //   clickable: true,
                  //   type: "custom",
                  //   renderCustom: (swiper, current, total)=>{
                  //       // console.log(current, total)
                  //       return `<div class="swiper-pagination swiper-pagination-fraction swiper-pagination-vertical text-400"><span class="swiper-pagination-current">0${current}</span>/<span class="swiper-pagination-total">0${total}</span></div>`;
                  //   }
                  // }}

                  // pagination={{
                  //   el: '.swiper-pagination',
                  //   clickable: true,
                  //   renderBullet: (index, className) => {
                  //     return '<span class="' + className + '">' + (index + 1) + '</span>';
                  //   }
                  // }}
                  modules={[Pagination, Controller, Autoplay]}
                  className="mySwiper2"
                  onSwiper={(swiper) => {
                    swiper2Ref.current = swiper;
                  }}
                  // onSwiper={setFirstSwiper}
                  loop={true}
                  navigation={false}
                  slidesPerView={1}
                  spaceBetween={0}
                  initialSlide={0}
                  allowTouchMove={false}
                  noSwiping={true}
                  speed={1000}
                  autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                  }}
                  onRealIndexChange={() => {
                      onSlideChange();
                    }
                  }
                  controller={{ control: swiper1Ref.current, inverse:true, navigation: false }}
                  // controller={{ control: firstSwiper, inverse:true }}
                  >
                    {data.sliderNewItems.reverse().map((slide, index)=>{
                      return (
                        <SwiperSlide className="slide" key={index}>
                          <div className="w-full h-full" style={{position:"relative"}}>
                            <div className="w-full h-full" style={{position:"absolute", top:"0", left:"0"}}>
                              {slide.slideRightImage ? 
                                <NextImage media={slide.slideRightImage} style={{objectFit:"cover", objectPosition:"left", width:"100%", height:"100%"}}/>
                              : ""}
                            </div>
                            <div className="flex flex-col justify-between w-full h-full px-8 py-10 text-white" style={{position:"absolute", top:"0", left:"0", backgroundColor: slide.slideRightImage ? "#00000022" : "transparent"}}>
                              <div></div>
                              <div className="text-center">
                                <h1 className="hero-title-right" style={{maxWidth:"100%"}}>{slide.slideRightTag}</h1>
                              </div>
                              <div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>


                  <div className="absolute bottom-0 left-0 z-10 w-full px-5 pb-5 md:pb-10 md:px-10">
                    <div className="flex items-center justify-between">
                      <div className="flex text-2xl md:text-5xl" style={{fontWeight:"600"}}>
                        <div className="mr-9 text-white slideshow-arrow slideshow-arrow-left" style={{minWidth:"1.75ch", transform:"rotate(180deg)"}} onClick={()=>{slideLeft()}}><Arrow /></div>
                        <div className="text-white slideshow-arrow slideshow-arrow-right" style={{minWidth:"1.75ch"}} onClick={()=>{slideRight()}}><Arrow /></div>
                      </div>
                      <div className="flex text-[24px] leading-[1.2] text-white md:text-[40px]" style={{fontWeight:"600"}}>
                        <div className="larish-font" style={{minWidth:"2ch"}}><p>0{currentSlide}</p></div>
                        <div className="larish-font" style={{minWidth:"2ch", textAlign:"center"}}><p>/</p></div>
                        <div className="larish-font" style={{minWidth:"2ch"}}><p>0{data.sliderNewItems.length}</p></div>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        {/* </React.Suspense> */}
      </main>
  )
}

export default Hero
