import React from 'react'
import Divider from '../elements/divider'
import Markdown from "react-markdown"
import remarkBreaks from 'remark-breaks'
import rehypeRaw from 'rehype-raw'

const Services = ({data}) => {
  return (
    <div className="container grid w-full gap-10 mt-20 mb-20 md:grid-cols-2">
        <div>
            <Divider/>
            <div className="" style={{fontSize:"20px", maxWidth:"60ch"}}>
                <h3 className="my-6 mt-2 font-bold" style={{fontSize:"20px", maxWidth:"30ch"}}>{data.title}</h3>
                <Markdown 
                    className="couto-mixto-markdown" 
                    remarkPlugins={[remarkBreaks]}
                    rehypePlugins={[rehypeRaw]}
                >
                    {data.description}
                </Markdown>
            </div>
        </div>
        <div className="grid gap-10 sm:grid-cols-2">
            {data.services.map((service,index)=>{
                // console.log(service.test)
                return (
                    <div key={index}>
                        <Divider />
                        <div className="pb-10">
                            <div className='mt-2 font-bold' style={{color:"#766733"}}>{`0${index + 1}`}</div>
                            <h3 className="mt-4 mb-2" style={{fontSize:"18px", fontWeight:"500"}}>{service.title}</h3>
                            {/* <Markdown className="couto-mixto-markdown" remarkPlugins={[remarkBreaks]}>{service.description}</Markdown> */}
                            <Markdown className="couto-mixto-markdown" remarkPlugins={[remarkBreaks]}>{service.dropdownContent}</Markdown>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default Services