import React from 'react'

const Arrow = () => {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.34 105.21"><g id="Layer_2-2"><path d="M132.48,49.22L87.83,4.74l4.91-4.74,52.6,52.6-52.6,52.6-4.91-4.74,44.65-44.48H0v-6.77h132.48Z" fill="currentColor"/></g></svg>
  )
}

export default Arrow

