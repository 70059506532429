import React from 'react'
import { getArticlesPaginated, getEventsPaginated, getProjectsPaginated } from '../../utils/api'
import ArticleCard from '../elements/article-card'
import Divider from '../elements/divider'
import EventCard from '../elements/event-card'
import ProjectCard from '../elements/project-card'

import ScrollTrigger from "gsap/dist/ScrollTrigger"
import gsap from "gsap"
import { useRouter } from 'next/router'
import { getGradientColors } from '../../utils/colors'

const FilterSection = ({data, fields, pageContext}) => {
    const [activeFilter, setActiveFilter] = React.useState("all");
    const [currentPage, setCurrentPage] = React.useState(1);
    const [gridData, setGridData] = React.useState(null);
    const router = useRouter()


    const onClickFilterHander = (tag) => {
        if(!tag){
            setActiveFilter("all");
            getData("all").then(()=>{
                ScrollTrigger.refresh();
                ScrollTrigger.update();
              });;
        }else{
            setActiveFilter(tag);
            getData(tag).then(()=>{
                ScrollTrigger.refresh();
                ScrollTrigger.update();
              });;
        }

    }
    const getData = async (tag) => {
        let res = null;
        switch(router.asPath){
            case "/projects":
                res = await getProjectsPaginated("", pageContext.locale, false, tag, currentPage, 10);
                break;
            case "/events":
                res = await getEventsPaginated("", pageContext.locale, false, tag, currentPage, 10);
                break;
            case "/articles":
                res = await getArticlesPaginated("", pageContext.locale, false, tag, currentPage, 10);
                break;
        }

        setGridData(res)
    }
    React.useEffect(()=>{
        getData(activeFilter).then(()=>{
            ScrollTrigger.refresh();
            ScrollTrigger.update();
          });;
    },[])

    const renderCards = () => {
        let res = null;
        switch(router.asPath){
            case "/projects":
                res = (
                <div className="grid gap-0 py-12 align-top md:grid-cols-2">
                    {gridData && gridData.map((project, index) => (
                        <ProjectCard event={project} key={index} isFilterSection={true}/>
                    ))}
                </div>
                )
                break;
            case "/events":
                res = (
                <div className="grid grid-cols-2 gap-5 py-12 align-top md:grid-cols-4 md:gap-0 margin-fix-cards">
                    {gridData && gridData.map((project, index) => (
                        <EventCard event={project} key={index} isFilterSection={true}/>
                    ))}
                </div>
                )
                break;
            case "/articles":
                res = (
                <div className="grid grid-cols-2 gap-5 py-12 align-top md:grid-cols-4 md:gap-0 margin-fix-cards">
                    {gridData && gridData.map((project, index) => (
                        <ArticleCard event={project} key={index} isFilterSection={true}/>
                    ))}
                </div>
                )
                break;
        }

        return res;
    }

  return (
    
    <div className="container w-full mt-20 mb-40" style={{minHeight:"100svh"}}>
        <div>
            <Divider/>
            <div className="" style={{fontSize:"20px", maxWidth:"60ch"}}>
                <h3 className="my-6 mt-2 font-bold" style={{fontSize:"20px", maxWidth:"30ch"}}>{data.title}</h3>
            </div>
        </div>
        <div className="">
            <div className="flex flex-wrap mt-10">
                {fields.map((field, index) => {
                    let colors = getGradientColors()[field.slug]
                    return (
                    <div key={index} onClick={(e)=>{onClickFilterHander(field.tag)}}
                        className={`flex flex-wrap items-center justify-center text-center text-sm md:text-md button my-2 mr-5 md:my-5 dark outline-cm md:mr-8 ${activeFilter === field.tag ? "active" : ""}`}
                        style={{"--color1-var": colors.color1, "--color2-var": colors.color2, "--text-hover":"#000"}}>{field.name}</div>
                    )
                })}
                <div onClick={(e)=>{onClickFilterHander()}} className={`flex flex-wrap items-center justify-center text-center text-sm md:text-md button my-2 mr-5 md:my-5 dark outline-cm md:mr-8 ${activeFilter === "all" ? "active" : ""}`}>Todos</div>
            </div>
        </div>
        <div>
            {renderCards()}
        </div>
    </div>
  )
}

export default FilterSection

// #6B514A
// #F55C30
// #1BBEAF