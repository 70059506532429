import NextImage from "../elements/image"
import Divider from "../elements/divider"
import ProjectCard from "../elements/project-card"
import React from "react"
import { getProjectsPaginated } from "../../utils/api"

import ScrollTrigger from "gsap/dist/ScrollTrigger"
import gsap from "gsap"

import { Controller, Navigation, Pagination, Autoplay, Mousewheel, Grid } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/controller';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import "swiper/css/grid";

import CustomLink from "../elements/custom-link"

const ProjectsHome = ({ data, fields, pageContext }) => {

  const [gridData, setGridData] = React.useState(null);
  const ref = React.useRef();
  const swiperRef = React.useRef();
  const timeline = React.useRef();
  const [showButtons, setShowButtons] = React.useState(false);
  // console.log("PROJECTS HOME", pageContext.projects)


  const getData = async () => {

    const field = fields.filter(function(e) {
      return e.slug === pageContext.slug;
    })

    let tag = "all"
    if (pageContext.slug && field.length > 0) {
      tag = field[0].tag;
    }
    const res = await getProjectsPaginated("rank:asc", pageContext.locale, false, "all", 1, 2);
    ScrollTrigger.update();
    ScrollTrigger.refresh();
    // const res = await getEventsPaginated("", pageContext.locale, false, "all", 1, 4);
    // res = await getArticlesPaginated("", pageContext.locale, false, tag, currentPage, 10);
    setGridData(res)
  }


  React.useEffect(()=>{
    // timeline.current = gsap.timeline({
    //   scrollTrigger: {
    //     id:"background_gradient_scrolltrigger",
    //     trigger: ref.current,
    //     start: 'top bottom',
    //     // end: `${vh(150)} top`,
    //     // scrub: 1,
    //     // markers:true,
    //     // start: start,
    //     // end: '90%'
    //   }
    // })

  }, [])
  
  React.useEffect(()=>{
      // getData().then(()=>{
      //   ScrollTrigger.refresh();
      //   ScrollTrigger.update();
      // });

    const title = ref.current.querySelector("h2")

    gsap.fromTo(title, {
      opacity:0,
      y:"20px"
    },
    {
      opacity:1,
      y:0,
      scrollTrigger: {
        trigger: title,
        start: "top 75%"
      },
      duration: 1.2,
      ease:"Power3.easeOut"
    },"<")

  },[])
  
  return (
    <div className="my-10 " style={{overflowX:"hidden"}} ref={ref}>
      <div className="container">
        <Divider />
        <div className="flex justify-between my-4">
          <h2 className="font-bold">{data.title}</h2>
          <CustomLink link={{ url:"/projects", newTab:false}}>
              <div className='p-1 px-10 text-center text-black hover:text-white hover:bg-black' style={{
                  maxWidth:"263px",
                  border: "1px solid #000000",
                  borderRadius: "22px",
                  fontSize:"14px",
                  transition:"0.3s ease-out"
              }}>{data.button.text}</div>
          </CustomLink>
        </div>
      </div>
      {/* <div className="grid gap-0 py-10 align-top md:grid-cols-2 margin-fix-cards"> */}
      <div className="container py-10 align-top margin-fix-cards" style={{width:"auto", overflowX:"hidden"}}>
        {/* {pageContext.projects && pageContext.projects.slice(0,2).map((project, index) => (
          <ProjectCard event={project} key={index}/>
        ))} */}

        <Swiper
            watchOverflow
            breakpoints={{
                640:{
                    slidesPerView: 1,
                    grid:{
                        rows:2,
                        fill:"row"
                    }
                },
                768:{
                    slidesPerView: 2,
                    spaceBetween: 0,
                    grid:{
                        rows:1,
                    }
                },
                1440:{
                    slidesPerView: 2,
                    spaceBetween: 0,
                    grid:{
                        rows:1,
                    }
                },
                1660:{
                    slidesPerView: 2,
                    spaceBetween: 0,
                    grid:{
                        rows:1,
                    }
                }
            }}
            mousewheel={{
                forceToAxis:true
            }}
            modules={[Pagination, Controller, Autoplay, Mousewheel, Grid]}
            className="mySwiper2"
            onSwiper={(swiper) => {
                swiperRef.current = swiper;
            }}
            onLock={()=>{
                setShowButtons(false)
            }}
            onUnlock={()=>{
                setShowButtons(true)
            }}
            navigation={false}

            slidesPerView={1}
            grid={{
                rows:2,
                fill:"row"
            }}
            spaceBetween={10}
            
            initialSlide={0}
            allowTouchMove={true}
            speed={700}
        >
            {pageContext.projects && pageContext.projects.slice(0,6).map((project, index) => {
                return (
                    <SwiperSlide className="slide" key={index}>
                      <ProjectCard event={project} first={index == 0} last={index == pageContext.projects.slice(0,6).length-1} key={index}/>
                    </SwiperSlide>
                )
            })}
        </Swiper>
      </div>
    </div>
  )
}

export default ProjectsHome
