import gsap from "gsap"
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import React from "react"
import Divider from "../elements/divider"
import NextImage from "../elements/image"
import GradientBackground from "../gradient/GradientBackground";
import GradientBalls from "../gradient/GradientBalls";
import { Color } from 'three';
import CustomLink from "../elements/custom-link";
import { scrolling } from "/utils/hooks";
import useIsomorphicLayoutEffect from "../../utils/useIsomorphicLayoutEffect";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin";
import { getGradientColors } from "../../utils/colors";
import Link from "../icons/Link";

const FieldsHome = ({ data, fields }) => {
  // console.log("\n\n\n FIELDS", fields)
  // const ballMaterialRef = React.useRef();
  const positionElementRef = React.useRef();
  const tagsRef = React.useRef();
  const ref = React.useRef();
  const timeline = React.useRef();
  const timeline2 = React.useRef();
  // const firstRender = React.useRef(true);
  const colorsStart = fields.map((field) => new Color(getGradientColors()[field.slug].color1).convertLinearToSRGB().getHex())
  const colorsEnd = fields.map((field) => new Color(getGradientColors()[field.slug].color2).convertLinearToSRGB().getHex())

  // const activeField = React.useRef(-1);
  const activeField = React.useRef(0);
  const isAnimating = React.useRef(false);
  const isActive = React.useRef(false);
  // console.log("FieldsHome", data)

  const listening = React.useRef(true);
  const direction = React.useRef("down");

  const touch = React.useRef({
    startX: 0,
    startY: 0,
    dx: 0,
    dy: 0,
    startTime: 0,
    dt: 0
  });

  const getCurrentScrollTrigger = () => {
    return timeline2.current.scrollTrigger;
  }

  function handleDirection() {
    listening.current = false;

    if (direction.current === "down") {
      // console.log("down")
      let next = activeField.current + 1;
      if (next > fields.length){
        listening.current = true;
        // console.log("ENDED BOT activate event")
        return ;
      }
      // console.log("POSITION", timeline2.current.scrollTrigger.labelToScroll(`field-element-${next}`))
      isAnimating.current = true;
      gsap
      .to(window, {
        duration: 1,
        scrollTo: timeline2.current.scrollTrigger.labelToScroll(`field-element-${next}`)
      })
      .then(()=>{
        listening.current = true
        isAnimating.current = false;
      });
      // timeline2.current.tweenFromTo(
      //   `field-element-${activeField.current}`,
      //   `field-element-${next}`,
      //   {
      //     onComplete:(listening) => { 
      //       listening.current = true
      //       // console.log("CALLBACK activate event")
      //     },
      //     onCompleteParams: [listening]
      //   }
      // );
      activeField.current = next;
      // slideIn();
    }else if (direction.current === "up") {
      // console.log("up")
      let next = activeField.current - 1;
      if (next < 0){
        listening.current = true;
        // console.log("ENDED TOP activate event")
        return ;
      }
      isAnimating.current = true;
      gsap
      .to(window, {
        duration: 1,
        scrollTo: timeline2.current.scrollTrigger.labelToScroll(`field-element-${next}`)
      })
      .then(()=>{
        listening.current = true
        isAnimating.current = false;
      });
      // timeline2.current.tweenFromTo(
      //   `field-element-${activeField.current}`,
      //   `field-element-${next}`,
      //   {
      //     onComplete:(listening)=>{ 
      //       listening.current = true
      //       // console.log("CALLBACK activate event")
      //     },
      //     onCompleteParams: [listening]
      //   }
      // );
      activeField.current = next;
      // if (next < 0) next = sections.length - 1;
      // slideOut();
    }
  }

  function handleWheel(e) {
    if(isAnimating.current){
      e.preventDefault()
      return ;
    }
    // console.log(e.wheelDeltaY, ScrollTrigger.getAll(), getCurrentScrollTrigger());
    // if(ScrollTrigger.getById("fieldScrollTrigger2")?.isActive || getCurrentScrollTrigger()?.isActive){
    if(getCurrentScrollTrigger()?.isActive){
      if (e.wheelDeltaY < 0) {
        let next = activeField.current + 1;
        if (next < fields.length){
          // console.log("prevent")
          e.preventDefault()
        }
      }else{
        let next = activeField.current - 1;
        if (next >= 0 ){
          // console.log("prevent")
          e.preventDefault()
        }
      }

      if (!listening.current){
        // console.log("not listening")
        return;
      }
      if (e.wheelDeltaY < -10) {
        direction.current = "down";

      }else if (e.wheelDeltaY > 10) {
        direction.current = "up";

      }else{
        return;
      }
      // console.log("listening")
      // direction.current = e.wheelDeltaY < 0 ? "down" : "up";

      handleDirection(e);
    }
  }

  function handleTouchStart(e) {
    if(isAnimating.current){
      e.preventDefault()
      return ;
    }
    if(ScrollTrigger.getById("fieldScrollTrigger2")?.isActive){
      if (!listening.current) return;
      const t = e.changedTouches[0];
      touch.current.startX = t.pageX;
      touch.current.startY = t.pageY;
    }
  }

  function handleTouchMove(e) {
    if(isAnimating.current){
      e.preventDefault()
      return ;
    }
    
    const t = e.changedTouches[0];
    touch.current.dy = t.pageY - touch.current.startY;
    if(ScrollTrigger.getById("fieldScrollTrigger2")?.isActive){
      if (touch.current.dy < 0) {
        let next = activeField.current + 1;
        if (next < fields.length){
          // console.log("prevent")
          e.preventDefault()
        }
      }else{
        let next = activeField.current - 1;
        if (next >= 0 ){
          // console.log("prevent")
          e.preventDefault()
        }
      }

      if (!listening.current){
        // console.log("not listening")
        return;
      }
    }
  }

  function handleTouchEnd(e) {
    if(isAnimating.current){
      e.preventDefault()
      return ;
    }
    if(ScrollTrigger.getById("fieldScrollTrigger2")?.isActive){
      if (!listening.current) return;
      const t = e.changedTouches[0];
      touch.current.dx = t.pageX - touch.current.startX;
      touch.current.dy = t.pageY - touch.current.startY;
      if (touch.current.dy > 10) direction.current = "up";
      if (touch.current.dy < -10) direction.current = "down";
      handleDirection();
    }
  }

  // React.useEffect(()=>{
  // useIsomorphicLayoutEffect(()=>{

    // ref.current.addEventListener("wheel", handleWheel);
  //   ref.current.addEventListener("touchstart", handleTouchStart);
  //   ref.current.addEventListener("touchmove", handleTouchMove);
  //   ref.current.addEventListener("touchend", handleTouchEnd);
  // });
  // React.useEffect(()=>{
  //   gsap.registerPlugin(ScrollTrigger)
  //   gsap.registerPlugin(ScrollToPlugin)
  // },[])

  // useIsomorphicLayoutEffect(()=>{
  React.useEffect(()=>{
    // console.log("ISOMORPHIC EFFECT FIELDS HOME")
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(ScrollToPlugin)

    if(timeline2.current){
      timeline2.current.kill();
      ScrollTrigger.update();
      ScrollTrigger.refresh();
    }


    

    // if(timeline.current){
    //   timeline.current.kill();
    //   ScrollTrigger.update();
    //   ScrollTrigger.refresh();
    // }

    // timeline.current = gsap.timeline({
    //   paused:true,
    //   scrollTrigger: {
    //     id:"fieldScrollTrigger1",
    //     trigger: ref.current,
    //     start: "top top", // when the top of the trigger hits the top of the viewport
    //     end: "bottom bottom", // end after scrolling 500px beyond the start
    //     // markers:true,
    //     onEnter:()=>{
    //       // scrolling.disable()
    //       console.log("testing start")
    //       // gsap.set(window, {
    //       //   scrollTo: ref.current.offsetHeight,
    //       // });
    //     },
    //     onLeave:(isAnimating)=>{
    //       // scrolling.enable()
    //       console.log("testing end")
    //     },
    //     onEnterBack:(isAnimating)=>{
    //       // scrolling.disable()
    //       console.log("testing start")
    //       // gsap.set(window, {
    //       //   scrollTo: ref.current.offsetHeight,
    //       // });
    //     },
    //     onLeaveBack:(isAnimating)=>{
    //       // scrolling.enable()
    //       console.log("testing end")
    //     },
    //   }
    // })

    timeline2.current = gsap.timeline({
      // paused:true,
      scrollTrigger: {
        id:"fieldScrollTrigger2",
        trigger: ref.current,
        start: "top top", // when the top of the trigger hits the top of the viewport
        end: "bottom bottom", // end after scrolling 500px beyond the start
        // toggleActions: "play none reverse reverse",
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        // markers:true,
        onEnter:()=>{

        },
        onEnterBack:()=>{

        },
        onLeave: () => {

        },
        onLeaveBack: () => {

        },
        onToggle: (self) => {
          isActive.current = self.isActive;
          // console.log("toggled to active:", self.isActive)
        },
        onUpdate: (self)=>{
          if(!isAnimating.current){
            let next = activeField.current + 1;
            let previous = activeField.current - 1;
            let ratio = 1/fields.length;
            // console.log("progress", self.progress.toFixed(3))
            // console.log( activeField.current, next, previous, ratio)
            let newValue = Math.round(self.progress.toFixed(3) / ratio)
            if(newValue != activeField)
              activeField.current = newValue
          }
        },
        snap: {
          // snapTo:1/fields.length,
          directional:true,
          inertia: false,
          snapTo: "labelsDirectional", // snap to the closest label in the timeline
          duration: {min: 0.75, max: 1.25}, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
          delay: 0, // wait 0.2 seconds from the last scroll event before doing the snapping
          ease: "power2.inOut" // the ease of the snap animation ("power3" by default)
        },
        // onSnapComplete: () => console.log(timeline2.current.currentLabel())
      }
    });

    gsap.set(positionElementRef.current.querySelector(".position-element"),{
      y: positionElementRef.current.querySelector(".field-element").offsetTop,
    })
    let positionElements = positionElementRef.current.querySelectorAll(".field-element");
    positionElements.forEach((element, index) => {
        let elIndex = element.getAttribute("index");
        let tagEl = tagsRef.current.querySelector(`#field-tag-${elIndex}`)
        let previousTagEl = tagsRef.current.querySelector(`#field-tag-${elIndex-1}`)
        let tagBackEl = tagsRef.current.querySelector(`#field-tag-back-${elIndex}`)
        let previousTagBackEl = tagsRef.current.querySelector(`#field-tag-back-${elIndex-1}`)
        let arrowEl = element.querySelector(`.field-arrow-wrapper`)

        gsap.set(element,{
          opacity: 0.3,
        }, `field-element-${index}`)

        gsap.set(tagEl, {
          opacity:0
        }, `field-element-${index}`)

        gsap.set(tagBackEl, {
          opacity:0
        }, `field-element-${index}`)

        gsap.set(arrowEl, {
          opacity:0.3
        }, `field-element-${index}`)

        // console.log(element, element.offsetTop * 0.875, positionElementRef.current.querySelector(".position-element").offsetTop)
        timeline2.current.addLabel(`field-element-${index}`, index).to(positionElementRef.current.querySelector(".position-element"),{
          y: element.offsetTop,
          height: "80px", //element.querySelector(".title").clientHeight * 1.8,
          ease: "Power2.easeInOut",
          // markers:true,
        }, `field-element-${index}`)

        timeline2.current.to(element,{
          opacity: 1.0,
          ease: "Power2.easeInOut",
        }, `field-element-${index}`)

        timeline2.current.to(tagEl,{
          opacity: 1.0,
          delay: 0.5,
          duration:0.3,
          ease: "Power3.easeInOut",
        }, `field-element-${index}`)
        timeline2.current.to(tagBackEl,{
          opacity: 1.0,
          delay: 0.5,
          duration:0.3,
          ease: "Power3.easeInOut",
        }, `field-element-${index}`)

        timeline2.current.to(arrowEl,{
          opacity: 1.0,
          // delay: 0.5,
          // duration:0.3,
          ease: "Power3.easeInOut",
        }, `field-element-${index}`)

        
        if(index > 0){

          let previousArrowEl = positionElements[index-1].querySelector(`#field-arrow-${index-1}`)
          
          timeline2.current.to(positionElements[index-1],{
            opacity: 0.3,
            ease: "Power3.easeInOut",
          }, `field-element-${index}`)

          timeline2.current.to(previousArrowEl,{
            opacity: 0.2,
            // delay: 0.3,
            // duration:0.3,
            ease: "Power3.easeInOut",
          }, `field-element-${index}`)

          timeline2.current.to(previousTagEl,{
            opacity: 0,
            delay: 0.3,
            duration:0.3,
            ease: "Power3.easeInOut",
          }, `field-element-${index}`)
          timeline2.current.to(previousTagBackEl,{
            opacity: 0,
            delay: 0.3,
            duration:0.3,
            ease: "Power3.easeInOut",
          }, `field-element-${index}`)


        }
    });
    timeline2.current.addLabel(`field-element-${positionElements.length}`, positionElements.length)



    // } // TESTING IF ITS THE KILL

    setTimeout(()=>{
      ref.current.addEventListener("wheel", handleWheel);
      // // ref.current.addEventListener("scroll", handleWheel);
      // ref.current.addEventListener("touchstart", handleTouchStart);
      // ref.current.addEventListener("touchmove", handleTouchMove);
      // ref.current.addEventListener("touchend", handleTouchEnd);
    }, 300)

    
    return () => {
      timeline2.current.scrollTrigger?.kill();
      timeline2.current.kill();
      ScrollTrigger.getById("fieldScrollTrigger2")?.kill();
      // timeline.current.kill();


      // ref.current.removeEventListener("wheel", handleWheel);
      // // // ref.current.addEventListener("scroll", handleWheel);
      // ref.current.removeEventListener("touchstart", handleTouchStart);
      // ref.current.removeEventListener("touchmove", handleTouchMove);
      // ref.current.removeEventListener("touchend", handleTouchEnd);
    }

  }, [])


  return (
    // <div className="container relative fields-section" ref={ref} style={{height:`${200}dvh`}}>
    <div className="container relative fields-section" ref={ref} style={{height:`${2 * fields.length * 100}svh`}}>
      <div className="flex items-center w-full" style={{ height:"100svh", position:"sticky", top:"0", alignSelf: "flex-start", zIndex:"1", transformStyle:"preserve-3d", transform:"translate3d(0,0,0)", backfaceVisibility:"hidden"}}>
        {/* <Divider />
        <h2 className="my-4 font-bold">{data.title}</h2> */}
        <div className="relative w-full md:grid md:grid-cols-2">
          <div className="absolute top-0 left-0 w-full h-full md:static">
            <div ref={tagsRef}  className="relative flex items-center justify-center w-full h-full">
                {fields.map((field, index) => (
                      <h3 className="absolute hidden font-bold md:block title field-tag larish-font" id={`field-tag-back-${index}`} key={field.id} style={{fontSize:"50px", color:"#5e5e5e"}}>{field.tag}</h3>
                ))}
                {/* <div className="w-full h-full px-10 md:px-20" style={{maxHeight:"50svh", maxWidth:"50svh"}}> */}
                <div className="w-full h-full px-10 md:px-20" style={{maxHeight:"50svh", maxWidth:"50svh"}}>
                  <GradientBalls colorsStart={colorsStart} colorsEnd={colorsEnd} positionsBlob={[0.5,0.5]} scale={1.5} circleScale={15} timeline={timeline2} />
                </div>
                {fields.map((field, index) => (
                      <h3 className="absolute hidden font-bold md:block title field-tag larish-font" id={`field-tag-${index}`} key={field.id} style={{fontSize:"50px", color:"#e5e5e5", mixBlendMode:"exclusion"}}>{field.tag}</h3>
                ))}
            </div>
          </div>
          <div className="flex w-full h-full py-5">
            <div ref={positionElementRef} className="flex flex-col py-5 align-top gap-10 md:py-12 lg:flex-wrap" style={{position:"relative", borderLeft:"1px solid #91919188"}}>
              <div className="position-element" style={{position:"absolute", top:"0px", left:"-3px", width:"5px", height:"0", background:"#000", borderRadius:"3px"}}></div>
              {fields.map((field, index) => (
                <div className="flex-1 field-element" index={`${index}`} key={field.id}>
                  <CustomLink link={{url: "/" + field.slug, id: field.id, text: field.name}}>
                    <div className="w-full mt-2 " style={{ maxWidth:"50ch" }}>
                      <div className="flex justify-between">
                        <div className="px-5 md:pl-12">
                          <h3 className="font-bold title larish-font" style={{fontSize:"26px", lineHeight:"1.2", maxWidth:"15ch"}}>{field.name}</h3>
                          <div className="w-0 divider-wrapper">
                            <Divider color="black"/>
                          </div>
                        </div>
                        <div className="w-4 h-4 mt-1">
                          <div className="relative field-arrow-wrapper" id={`field-arrow-${index}`} >
                            <div className="relative main-arrow">
                              <Link/>
                            </div>
                            <div className="absolute w-full h-full hover-arrow">
                              <Link />
                            </div>
                          </div>
                        </div>
                      </div>
                    <p className="block px-5 mt-2 md:px-12 description" style={{fontSize:"14px", lineHeight:"1.2", maxWidth:"50ch", color:"#484747"}}>{field.description}</p>
                    </div>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FieldsHome





// const sections = document.querySelectorAll("section");
// const images = document.querySelectorAll(".bg");
// const headings = gsap.utils.toArray(".section-heading");
// const outerWrappers = gsap.utils.toArray(".outer");
// const innerWrappers = gsap.utils.toArray(".inner");

// document.addEventListener("wheel", handleWheel);
// document.addEventListener("touchstart", handleTouchStart);
// document.addEventListener("touchmove", handleTouchMove);
// document.addEventListener("touchend", handleTouchEnd);

// let listening = false,
//   direction = "down",
//   current,
//   next = 0;

// const touch = {
//   startX: 0,
//   startY: 0,
//   dx: 0,
//   dy: 0,
//   startTime: 0,
//   dt: 0
// };

// const tlDefaults = {
//   ease: "slow.inOut",
//   duration: 1.25
// };

// const splitHeadings = headings.map((heading) => {
//   return new SplitText(heading, {
//     type: "chars, words, lines",
//     linesClass: "clip-text"
//   });
// });

// function revealSectionHeading() {
//   return gsap.to(splitHeadings[next].chars, {
//     autoAlpha: 1,
//     yPercent: 0,
//     duration: 1,
//     ease: "power2",
//     stagger: {
//       each: 0.02,
//       from: "random"
//     }
//   });
// }

// gsap.set(outerWrappers, { yPercent: 100 });
// gsap.set(innerWrappers, { yPercent: -100 });

// // Slides a section in on scroll down
// function slideIn() {
//   // The first time this function runs, current is undefined
//   if (current !== undefined) gsap.set(sections[current], { zIndex: 0 });

//   gsap.set(sections[next], { autoAlpha: 1, zIndex: 1 });
//   gsap.set(images[next], { yPercent: 0 });
//   gsap.set(splitHeadings[next].chars, { autoAlpha: 0, yPercent: 100 });

//   const tl = gsap
//     .timeline({
//       paused: true,
//       defaults: tlDefaults,
//       onComplete: () => {
//         listening = true;
//         current = next;
//       }
//     })
//     .to([outerWrappers[next], innerWrappers[next]], { yPercent: 0 }, 0)
//     .from(images[next], { yPercent: 15 }, 0)
//     .add(revealSectionHeading(), 0);

//   if (current !== undefined) {
//     tl.add(
//       gsap.to(images[current], {
//         yPercent: -15,
//         ...tlDefaults
//       }),
//       0
//     ).add(
//       gsap
//         .timeline()
//         .set(outerWrappers[current], { yPercent: 100 })
//         .set(innerWrappers[current], { yPercent: -100 })
//         .set(images[current], { yPercent: 0 })
//         .set(sections[current], { autoAlpha: 0 })
//     );
//   }

//   tl.play(0);
// }

// // Slides a section out on scroll up
// function slideOut() {
//   gsap.set(sections[current], { zIndex: 1 });
//   gsap.set(sections[next], { autoAlpha: 1, zIndex: 0 });
//   gsap.set(splitHeadings[next].chars, { autoAlpha: 0, yPercent: 100 });
//   gsap.set([outerWrappers[next], innerWrappers[next]], { yPercent: 0 });
//   gsap.set(images[next], { yPercent: 0 });

//   gsap
//     .timeline({
//       defaults: tlDefaults,
//       onComplete: () => {
//         listening = true;
//         current = next;
//       }
//     })
//     .to(outerWrappers[current], { yPercent: 100 }, 0)
//     .to(innerWrappers[current], { yPercent: -100 }, 0)
//     .to(images[current], { yPercent: 15 }, 0)
//     .from(images[next], { yPercent: -15 }, 0)
//     .add(revealSectionHeading(), ">-1")
//     .set(images[current], { yPercent: 0 });
// }

// function handleDirection() {
//   listening = false;

//   if (direction === "down") {
//     next = current + 1;
//     if (next >= sections.length) next = 0;
//     slideIn();
//   }

//   if (direction === "up") {
//     next = current - 1;
//     if (next < 0) next = sections.length - 1;
//     slideOut();
//   }
// }

// function handleWheel(e) {
//   if (!listening) return;
//   direction = e.wheelDeltaY < 0 ? "down" : "up";
//   handleDirection();
// }

// function handleTouchStart(e) {
//   if (!listening) return;
//   const t = e.changedTouches[0];
//   touch.startX = t.pageX;
//   touch.startY = t.pageY;
// }

// function handleTouchMove(e) {
//   if (!listening) return;
//   e.preventDefault();
// }

// function handleTouchEnd(e) {
//   if (!listening) return;
//   const t = e.changedTouches[0];
//   touch.dx = t.pageX - touch.startX;
//   touch.dy = t.pageY - touch.startY;
//   if (touch.dy > 10) direction = "up";
//   if (touch.dy < -10) direction = "down";
//   handleDirection();
// }

